import React, { useState, useEffect, useContext } from 'react'
import { ErrorBelowInputField } from './elements/Errors'
import { InputText } from './elements/Inputs'
import SubmitButton, { ButtonFontSize } from './elements/SubmitButton'
import TrackingResultsModal from '../home/TrackingResultsModal'
import { TrackingFormContext } from '../context/TrackingFormContext'
import { useRouter } from 'next/router'
import { event } from '@/utils/gtag'
import { useSession } from 'next-auth/react'
import { retryAxiosInstance, globalConfig } from '@/types/retry'
import { FreightBill } from '@/libs/types'
import { formatRequestBodyDate } from '@/utils/portal/dates'

const TrackingForm: React.FunctionComponent = () => {
  const [tracking, setTracking] = useState('')
  const [loading, setLoading] = useState(false)
  const [serializedFormData, setSerializedFormData] = useState('')
  const { trackingFormApiResponse, setTrackingFormApiResponse } =
    useContext(TrackingFormContext)
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState<boolean>(false)
  const router = useRouter()
  const { data: session, status } = useSession()

  useEffect(() => {
    setTracking('')
  }, [])

  const formData = {
    pronumber: tracking,
  }

  const submitForm = async () => {
    setLoading(true)

    const serializedData = new URLSearchParams(formData).toString()
    const { endDate } = formatRequestBodyDate(1)

    const payload = {
      accountIds: session?.user.referenceNumbers || [],
      beginDate: '00000101',
      endDate: endDate,
      limit: 1000,
      orderBy: [{ pickupDate: 'desc' }],
      referenceNumber: tracking,
    }

    try {
      if (process.env.SENTRY_ENV === 'production')
        event({
          action: 'Tracking Form Submitted',
          category: 'Tracking',
          label: 'Tracking Form',
          value: status,
        })

      const response = await retryAxiosInstance.post(
        `/api/portal/freightBills`,
        payload,
        {
          ...globalConfig,
          timeout: 10000,
        }
      )

      const freightBills = response.data.freightBills

      const correctBill =
        freightBills.length === 1
          ? freightBills[0]
          : findCorrectFreightBill(
              freightBills,
              session?.user.referenceNumbers || []
            )

      const proNumber =
        correctBill.nptproNumber ||
        correctBill.advanceInterlinePRO ||
        correctBill.beyondInterlinePRO

      if (freightBills.length > 0) {
        setSerializedFormData(serializedData)
        router.push({
          pathname: `/portal/account/shipments/${proNumber}`,
          query: { nptproNumber: proNumber, isCartage: correctBill.isCartage },
        })
      } else {
        setLoading(false)
        setTrackingFormApiResponse({
          errors: 'Y',
          bolermsg: 'Unable to find a shipment with this number',
        })
      }
    } catch (error: any) {
      setLoading(false)
      setTrackingFormApiResponse({
        errors: 'Y',
        bolermsg:
          'Unable to track shipment at this time. Please try again later.',
      })
      console.error('/api/portal/freightBills Failed after 3 attempts')
      console.log(error)
    }
  }

  const hasMatchingCode = (
    bill: FreightBill,
    referenceNumbers: any[]
  ): boolean => {
    return (
      referenceNumbers.includes(bill.shipperCode) ||
      referenceNumbers.includes(bill.consigneeCode) ||
      referenceNumbers.includes(bill.billToCode) ||
      referenceNumbers.includes(bill.advanceInterline) ||
      referenceNumbers.includes(bill.beyondInterline)
    )
  }

  const findCorrectFreightBill = (
    freightBills: FreightBill[],
    referenceNumbers: any[]
  ) => {
    let nptproBill: FreightBill | null = null

    for (const bill of freightBills) {
      // If bill's account codes matches user's account codes
      if (hasMatchingCode(bill, referenceNumbers)) {
        return bill
      }

      if (!nptproBill && bill.nptproNumber) {
        nptproBill = bill
      }
    }

    return nptproBill || freightBills[0]
  }

  return (
    <section>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          submitForm()
        }}
      >
        <div className="items-end mt-3 md:mt-6 md:flex md:space-x-4">
          <div className="flex-1">
            <InputText
              labelText="Search by Freight Bill, Interline, or Shipper Number"
              type="text"
              placeholder="Add number"
              value={tracking}
              characterMax={15}
              onChange={(event: any) => {
                setTrackingFormApiResponse({})
                setTracking(event.target.value)
              }}
            />
          </div>
        </div>

        {trackingFormApiResponse.errors === 'Y' && (
          <ErrorBelowInputField
            errorMessage={trackingFormApiResponse.bolermsg}
          />
        )}

        <div className="mt-3 md:mt-6">
          <p className="mb-3 text-sm md:mb-5 md:text-nptBase">
            <a
              href="/login"
              className="underline text-blue hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              Sign in
            </a>{' '}
            to your customer account in order to view your Images, Documents,
            and Account Details.
          </p>
          <p className="mb-3 text-sm md:mb-5 md:text-nptBase">
            Or&nbsp;
            <a
              href="/register"
              className="underline text-blue hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              create an account
            </a>{' '}
            and you will be up running in no time.
          </p>
        </div>

        <SubmitButton
          text={loading ? 'Loading...' : 'Track Shipment'}
          fontSize={ButtonFontSize.small}
          isDisabled={tracking ? (loading ? true : false) : true}
        />
      </form>

      {trackingFormApiResponse.errors === 'N' && isTrackingModalOpen && (
        <TrackingResultsModal
          isTrackingModalOpen={isTrackingModalOpen}
          setIsTrackingModalOpen={setIsTrackingModalOpen}
          apiResponse={trackingFormApiResponse}
          serializedFormData={serializedFormData}
        />
      )}
    </section>
  )
}

export default TrackingForm
